// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-howto-enjoy-js": () => import("./../../../src/pages/howto-enjoy.js" /* webpackChunkName: "component---src-pages-howto-enjoy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-log-js": () => import("./../../../src/pages/log.js" /* webpackChunkName: "component---src-pages-log-js" */),
  "component---src-pages-ranking-js": () => import("./../../../src/pages/ranking.js" /* webpackChunkName: "component---src-pages-ranking-js" */),
  "component---src-pages-whats-sake-js": () => import("./../../../src/pages/whats-sake.js" /* webpackChunkName: "component---src-pages-whats-sake-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blogDetail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-log-history-js": () => import("./../../../src/templates/logHistory.js" /* webpackChunkName: "component---src-templates-log-history-js" */),
  "component---src-templates-paginated-js": () => import("./../../../src/templates/paginated.js" /* webpackChunkName: "component---src-templates-paginated-js" */)
}

